import React from 'react';

function PustPappaPust() {
  return (
    <div className="about-me-container">
      <div className="about-me-content d-flex justify-content-around">
        <div className="row">
          <div className="col-md-12">
            <h1>Pust, pappa. Pust</h1>
            <p>
              Om stykket:
              Pust, pappa. Pust er et stykke om tap, sorg og selvmord i nær relasjon. Det handler om å bli konfrontert med fortiden sin og det å leve med en suicidal far.
            </p>
            <p>
              Pust, pappa. Pust ble en utforskning av sorg og minner basert på mine egne erfaringer med selvmord i nær familie. Det begynte med enkeltstående tekster jeg skrev etter pappas første selvmordsforsøk, som jeg fant noen år senere.
              Jeg syns denne tematikken er viktig da jeg som pårørende har en opplevelse av at temaet er skambelagt. Da min familie valgte å være åpen om pappas død var det mange som delte lignende erfaringer. Jeg ser mer og mer viktigheten av å tørre og snakke om psykisk helse.
              Pust, pappa. Pust ble et utløp for alle tanker, også de forbudte: Det jeg trengte å si og det jeg ikke kunne si. Jeg håper det kan hjelpe andre til å tørre og snakke om disse følelsene.
            </p>
            <p>
              <a href="https://www.facebook.com/ungeviken/videos/bli-bedre-kjent-med-ester-gjermundnes-som-er-med-i-debutantprogrammet-til-ung-te/867092493826919/">Youtube-link til intervju og smakebiter fra stykket</a>
            </p>
            <p>
              <a href="https://www.driva.no/nyheter/i/1Bg3El/ester-31-mistet-pappa-i-selvmord-skrev-teater">Interju</a>
            </p>
            <p>
              <a href="https://www.songbirdagency.no/verk/pust%2C-pappa.-pust.-">Link till manus</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PustPappaPust;
