import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import './Header.css';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [fontSize, setFontSize] = useState('40px');
  const menuRef = useRef();

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const iconStyle = {
    color: 'white',
    fontSize: fontSize,
  };

  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-dark p-md-3">
        <div className="container">
        <Link to="/" className="navbar-brand">
  <h1 style={{ fontSize: '24px', color: 'white' }}>ESTER GJERMUNDNES</h1>
</Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <AiOutlineClose style={iconStyle} />
            ) : (
              <AiOutlineMenu style={iconStyle} />
            )}
          </button>
          <div
            ref={menuRef}
            className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
          >
            <div className="mx-auto"></div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to="/about"
                  className="nav-link text-white"
                  onClick={closeMenu}
                >
                  BIOGRAFI
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-white"
                  href="#"
                  id="stykkerDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  STYKKER
                </a>
                <div
                  className={`dropdown-menu ${isMenuOpen ? 'hide' : ''}`}
                  aria-labelledby="stykkerDropdown"
                >
                  <Link
                    to="/PustPappaPust"
                    className="dropdown-item"
                    onClick={closeMenu}
                  >
                    Pust, pappa. Pust
                  </Link>
                  <Link
                    to="/Skrot"
                    className="dropdown-item"
                    onClick={closeMenu}
                  >
                    Skrot
                  </Link>
                  <Link
                    to="/kallmegfoniks"
                    className="dropdown-item"
                    onClick={closeMenu}
                  >
                    Kall meg Føniks
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className="nav-link text-white"
                  onClick={closeMenu}
                >
                  KONTAKT
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
