import React from 'react';

function KallMegFoniks() {
  return (
    <div className="about-me-container">
      <div className="about-me-content d-flex justify-content-around">
        <div className="row">
          <div className="col-md-12">
            <h1>Kall meg Føniks</h1>
            <p>
              Kall meg Føniks var en idé som kom til meg gradvis. Jeg syns det var spennende å gruble på hva man ville reddet i en brann, og hva man velger å legge igjen. Slik kom tanken på at en brann kan være inngangen til en ny start for noen som ønsker å legge fortiden bak seg. Symbolet føniks ble derfor naturlig å bruke, da føniks gjenfødes ut av sin egen aske.
            </p>
            <p>
              Jeg holdt en workshop for Ung Tekst hvor vi jobbet hvordan man kan utvikle manus formmessig, som Maria Petronella Muri Nygren har brukt i sine manus. Denne måten å skrive på handlet om å legge på flere lag i teksten, som både skulle være monolog, dialog, fakta og myter. Dette inspirerte meg til å leke meg mer med hva scenetekst kan være, og resultatet ble Kall meg Føniks.
            </p>
            <p>
              Støttet av Dramatikkens Hus.
            </p>
            <p>
              Lenke til manus: (Kommer)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KallMegFoniks;
