import React from 'react';
import './AboutMe.css'; // Import the CSS file

function AboutMe() {
  return (
    <div className="about-me-container">
      <div className="about-me-content d-flex justify-content-around">
        <div className="row">
          <div className="col-md-6">
            <h1>Biografi</h1>
            <p>
 Ester Gjermundnes (f.1989) var en del av Debutantprogrammet ved Unge Viken Teater og debuterte med manuset 'Pust, pappa. Pust' desember 2020. Ester er utdannet dramapedagog fra Oslo Met i tillegg har hun en bachelor i sosialt arbeid fra Diakonhjemmet Høgskole. Hun jobber blant annet som sufflør, teaterinstruktør, dramaturg og regissør. Ester er også en av de som startet og driver Stueproduksjoner, som satt opp hennes stykke 'Skrot' i 2020.
            </p>
          </div>
          <div className="col-md-6">
            <h2>Manus</h2>
            <ul>
              <li>Skrot (2020)</li>
              <li>Pust, pappa. Pust (2020)</li>
            </ul>
            <h2>Utgivelser</h2>
            <ul>
              <li>Utdrag Pust, pappa. Pust i Ung Tekst antologi (2021)</li>
              <li>Utdrag Kall meg Føniks i Ung tekst antologi (2022)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
