import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import BootstrapCarousel from './Components/BootstrapCarousel';
import AboutMe from './Components/AboutMe';
import PustPappaPust from './Components/PustPappaPust';
import Skrot from './Components/Skrot';
import KallMegFoniks from './Components/KallMegFoniks';
import Contact from './Components/Contact';

function App() {
  return (
    <div>
      {/* Skip-to-content link */}
      <style>
        {`
          .skip-to-content {
            position: absolute;
            left: -9999px;
            z-index: 999;
            padding: 1em;
            background-color: var(--background);
            color: var(--text);
            opacity: 0;
          }
          .skip-to-content:focus {
            left: 50%;
            transform: translateX(-50%);
            opacity: 1;
          }
        `}
      </style>
      <a href="/about" className="skip-to-content" tabIndex={0}>
        Skip to Main Content
      </a>
      <Header />
      <Routes>
        <Route path="/" element={<BootstrapCarousel />}>
          Home
        </Route>
        <Route path="/about" element={<AboutMe />}>
          About Me
        </Route>
        <Route path="/pustpappapust" element={<PustPappaPust />}>
          Pust, pappa. Pust
        </Route>
        <Route path="/skrot" element={<Skrot />}>
          Scrap and Recycling
        </Route>
        <Route path="/kallmegfoniks" element={<KallMegFoniks />}>
          Kall Meg Foniks
        </Route>
        <Route path="/contact" element={<Contact />}>
          Contact
        </Route>
        {/* Add more routes as needed with descriptive link text */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
