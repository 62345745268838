import React from 'react';
import ContactForm from './ContactForm';
import './AboutMe.css'; // Import the CSS file

function Contact() {
  return (
    <div className="about-me-container">
      <div className="about-me-content d-flex justify-content-around">
        <div className="row">
          <div className="col-md-6">
            <h1>Kontakt meg</h1>
            <p>
              {/* Your contact page content here */}
            </p>
            <p>
              Email:{' '}
              <a href="mailto:your-email@example.com">ester.gjermundnes@gmail.com</a>.
            </p>
          </div>
          <div className="col-md-6">
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
