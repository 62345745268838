import React from 'react';

function Skrot() {
  return (
    <div className="about-me-container">
      <div className="about-me-content d-flex justify-content-around">
        <div className="row">
          <div className="col-md-12">
            <h1>SKROT</h1>
            <p>
              Hvor godt kjenner vi våre aller nærmeste? Og hva vet vi egentlig om
              livene til våre foreldre? Disse spørsmålene har jeg stilt meg flere ganger i løpet av de siste årene etter jeg selv mistet min far. I møte med døden kommer en rekke noe absurde situasjoner, som blant annet å rydde opp etter den som har gått bort.
            </p>
            <p>
              Hva om man innimellom alle tingene, finner noe som ikke kan forklares? Nå var jeg heldig og kjente min fars sider godt, og det var lite overraskelser etter hans bortgang. Men denne tanken fortsatte å gro i meg. Derfor valgte jeg å intervjue andre som også har mistet en forelder. Under intervjuene og samtalene kom det frem mange likheter, i tillegg til noen veldig unike situasjoner. Disse inspirerte meg videre i manusarbeidet og ble til ‘Skrot’.
            </p>
            <p>
              I denne forestillingen møter vi tre søsken som har mistet sin far, men som møter sorgen på tre helt forskjellige måter. De oppdager nye sider ved faren mens de pakker opp og rydder ned hans liv og hemmeligheter. I tillegg har broren tatt med seg sin forlovede, som gjør hele situasjonen litt ekstra problematisk. For hvem ønsker vel egentlig at noen utenforstående skal være med på noe som er så sårbart?
            </p>
            <p>
              Noe som gikk igjen i intervjuene var at familier sjeldent snakker sammen om det som er vanskelig. Det at ting ikke blir snakket om er noe som søsknene blir utfordret på.
            </p>
            <p>
              Og hvis sannheten kommer frem, ønsker man egentlig å vite det?
            </p>
            <p>
              Selv om denne historien er fiktiv, så er den inspirert av ekte historier og mennesker. Jeg håper stykket kan være gjenkjennbart, men mest av alt håper jeg det kan være en inspirasjonskilde til samtaler om det som er vanskelig.
            </p>
            <p>
              Link til anmeldelse: <a href="https://teaterungdom.blogg.no/skrot.html">Anmeldelse Link</a>
            </p>
            <p>
              Link til manus: (kommer)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skrot;
