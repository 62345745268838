import React from 'react';
import { BsInstagram, BsFacebook, BsLinkedin, BsEnvelope } from 'react-icons/bs';
import './Footer.css';

const iconStyles = {
  fontSize: '40px',
  cursor: 'pointer',
  color: 'white',
  margin: '0 20px',
};

function Footer() {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://www.instagram.com/estergjermundnes/" style={iconStyles} aria-label="Instagram Profile">
          <BsInstagram />
        </a>
        <a href="https://www.facebook.com/ester.gjermundnes" style={iconStyles} aria-label="Facebook Profile">
          <BsFacebook />
        </a>
        <a href="https://www.linkedin.com/in/ester-gjermundnes-9b15777a/?originalSubdomain=no" style={iconStyles} aria-label="LinkedIn Profile">
          <BsLinkedin />
        </a>
        <a href="mailto:ester.gjermundnes@gmail.com" style={iconStyles} aria-label="Email">
          <BsEnvelope />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
